import { generateLogo } from "./main";

window.addEventListener("DOMContentLoaded", () => {
	generateLogo({
		name: "Alas! Not found.",
		fontName: "Ranchers",
		fontUrl: "https://fonts.googleapis.com/css2?family=Ranchers&display=swap",
		backgroundClassName: "bg9",
		newlines: true,
		centre: false,
		ampersands: true,
		colourtitle: false,
		colourwords: true,
		case: "uppercase",
		path: "/404",
	});

	const refreshButton = document.querySelector("button#refresh");
	refreshButton.addEventListener("click", (e) => {
		e.preventDefault();

		const SPIN_INTERVAL_MS = 500;
		// start the spinner animation and wait for a complete cycle before redirecting
		refreshButton.disabled = true;
		setTimeout(() => {
			refreshButton.disabled = "";
			location = "/";
		}, SPIN_INTERVAL_MS);
	});
});
